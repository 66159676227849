import { isNotBlank } from '@iheartradio/web.utilities';

export const getPodcastProfileSlug = (slugAndId?: string) => {
  if (!isNotBlank(slugAndId)) {
    throw new Response(`Invalid Podcast Profile Slug: ${slugAndId}`, {
      status: 404,
    });
  }

  const slugArray = slugAndId.split('-');

  const podcastId = slugArray.pop();
  const slug = slugArray.join('-');

  if (
    !isNotBlank(podcastId) ||
    !Number.isInteger(Number.parseInt(podcastId, 10))
  ) {
    throw new Response(`Invalid Podcast Profile Slug: ${slugAndId}`, {
      status: 404,
    });
  }

  return {
    podcastId: Number.parseInt(podcastId, 10),
    slug,
  };
};

export const getPodcastSlugFromWebLink = (url?: string | null): string => {
  if (isNotBlank(url)) {
    try {
      const webLink = new URL(url);
      const slug = webLink.pathname
        .split('/')
        .filter(v => v !== '')
        .at(-1);
      if (isNotBlank(slug)) {
        const slugArray = slug.split('-');
        slugArray.pop();
        return slugArray.join('-');
      } else {
        return '';
      }
    } catch {
      return '';
    }
  } else {
    return '';
  }
};
